import styled from 'styled-components';
import _ from 'lodash';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import CardFullV from 'src/components/cards/card-full-v';
import CardH from 'src/components/cards/card-h';
import Carousels from 'src/components/carousels';
import { KEY_ADS_POSITION } from '../ads/dynamic-ads';
import MediaQuery from 'react-responsive';
import { isExistAds } from 'src/utils/helper';
import dynamic from 'next/dynamic';
const DynamicAds = dynamic(import('src/components/ads/dynamic-ads'));
import { useRouter } from 'next/router';
import { APP_IMG } from 'src/constants';
const SectionFullVH = ({ heading = '', path = '#', data = [], dataFull = [], eventDataLayer, ads }) => {
  if (_.isEmpty(data)) return null;
  const router = useRouter();
  return (
    <>
      {!_.isEmpty(data) && (
        <SectionFVHWrapper className='row' data-aos='fade-up'>
          <div className='col-12 col-lg-8'>
            {!_.isEmpty(dataFull) ? (
              <>
                {
                  <Carousels slideShow={1}>
                    {dataFull.map((item, index) => (
                      <CardFullV
                        key={index}
                        data={item}
                        path={item.link}
                        //! DATALAYER
                        eventDataLayer={{
                          ...eventDataLayer,
                          data: {
                            block: '1',
                            title: item?.title,
                            carousel: index + 1,
                            heading: 'Highlight'
                          }
                        }}
                      />
                    ))}
                  </Carousels>
                }
              </>
            ) : (
              <div className='no-data'>
                <img className='no-article' src={`${APP_IMG}/images/cropped-khobsanam-logo-1.png`} loading='lazy' alt='no-article' title='no-article' width={200} height={200} />
              </div>
            )}
            {isExistAds(ads, KEY_ADS_POSITION.INARTICLE_1) && (
              <div>
                <MediaQuery maxWidth={768}>
                  <DynamicAds position={KEY_ADS_POSITION.INARTICLE_1} data={ads} />
                </MediaQuery>
              </div>
            )}
          </div>

          {/* ------------------------------- LATEST NEWS ------------------------------ */}
          <div className='col-12 col-lg-4 mt-30 mt-lg-0 mb-30'>
            <h2 className='heading mb-lg-30'>
              <a
                title={heading}
                href={path}
                //! DATALAYER
                // onClick={() =>
                //   sendDataLayerGlobal({
                //     key: KEY_EVENT.TRACK_EVENT,
                //     event: KEY_EVENT.CONTENT_CATEGORY,
                //     data: {
                //       title: heading,
                //       category: heading
                //     }
                //   })
                // }
              >
                {' '}
                {heading}{' '}
              </a>
            </h2>
            {data.map((item, index) => (
              <CardH
                key={index}
                data={item}
                path={item.link}
                //! DATALAYER
                eventDataLayer={{
                  ...eventDataLayer,
                  data: {
                    block: '2',
                    title: item?.title,
                    index: index + 1,
                    heading: heading
                  }
                }}
              />
            ))}
          </div>
        </SectionFVHWrapper>
      )}
    </>
  );
};

const SectionFVHWrapper = styled.div`
  .card-full-v-content {
    margin: 0 5px;
  }
  @media (max-width: 768px) {
    .heading {
      margin-bottom: 25px;
    }
  }
  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    height: 600px;
  }
  @media (max-width: 475px) {
    .no-data {
      height: auto;
    }
  }
`;

export default SectionFullVH;
