import Carousel from 'react-slick';

const Carousels = ({
  children,
  slideShow = 1,
  responsiveShow = 3,
  autoplay = true,
  vertical = false,
  autoplaySpeed,
  infinite = true
}) => {
  const settings = {
    dots: true,
    speed: 1000,
    infinite: infinite,
    slidesToShow: slideShow || 3,
    slidesToScroll: 1,
    autoplay: autoplay,
    autoplaySpeed: autoplaySpeed || 3000,
    pauseOnHover: true,
    vertical: vertical,
    verticalSwiping: vertical,
    swipeToSlide: vertical,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slideShow || 1,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: vertical ? 2 : responsiveShow,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: vertical ? 2 : 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };
  return (
      <Carousel {...settings}>
        {children}
      </Carousel>
  );
};

export default Carousels;
