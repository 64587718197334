import dayjs from 'dayjs';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import Image from 'src/components/image';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { incrementCountview } from 'src/utils/increment-countview';
// import numeral from 'numeral';
import { usePageViews } from 'src/utils/helper';
import { FaRegCalendarAlt, FaRegEye } from 'react-icons/fa';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

dayjs.extend(buddhistEra);

const CardFullV = ({ data, path, showBlurb = true, badge = false, byline = true, showCountview = true, eventDataLayer }) => {
  const categoryNameTH = data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;
  const [countView, setCountView] = useState(0);
  const badgeDatalayer = data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;

  useEffect(async () => {
    await incrementCountview(data)
      .then(item => setCountView(item))
      .catch(() => setCountView(0));
  }, []);

  const dateUpdate = dayjs(data?.published_at || data?.dateDefault)
    .locale('th')
    .format('DD MMM BBBB');
  return (
    <a
      href={path}
      tabIndex='-1'
      title={data?.title}
      target='_self'
      className='card-wrapper'
      //! DATALAYER
      onClick={() =>
        sendDataLayerGlobal({
          ...eventDataLayer
        })
      }
    >
      <CardFullvStyle>
        <div className='card-full-v-thumb'>
          <Image image={data?.image} title={data?.title} type='CARD_LARGE' />
        </div>
        {badge && <span className='badge'>{categoryNameTH}</span>}
        <div className='card-full-v-content'>
          <h3 className='card-full-v-content-title text-excerpt-2'>{data?.title}</h3>
          {showBlurb && data?.blurb && <p className='card-full-v-content-blurb text-excerpt-2'>{data?.blurb}</p>}
          <p className='readmore'>อ่านต่อ ...</p>
          <div className='border-style-full-v' />
          <div className='card-full-v-content-meta'>
            {byline && (
              <div className='byline-card-full-v'>
                <span>{data?.byline}</span>
              </div>
            )}
            <div className='date-count-card-full-v'>
              <FaRegCalendarAlt />
              <span className='date'>{dateUpdate}</span>
              {showCountview && (countView > 0 || data.pageviews > 0) && (
                <span className='pageview'>
                  <FaRegEye /> {usePageViews(countView) || usePageViews(data?.pageviews)}
                </span>
              )}
            </div>
          </div>
        </div>
      </CardFullvStyle>
    </a>
  );
};

const CardFullvStyle = styled.div`
  .card-full-v-thumb {
    .resolution-image {
      border-radius: 3px;
    }
  }
  &:hover {
    .card-full-v-content {
      h3 {
        color: #00c040;
      }
    }
  }

  /* .card-full-v-content-title {
      min-height:75px;
    }
    
    .card-full-v-content-blurb {
      min-height:48px;
    } */

  .card-full-v-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 15px;

    h3 {
      transition: 0.5s ease-out 100ms;
      font-size: 24px;
      margin: 20px 0 0 0;
      color: rgba(0, 2, 28, 0.95);
    }
    p {
      transition: 0.5s ease-out 100ms;
      font-size: 18px;
    }
    .readmore {
      border-radius: 3px;
      border: 1px solid rgba(0, 2, 28, 0.95);
      background-color: rgba(0, 2, 28, 0.95);
      width: fit-content;
      padding: 5px 10px;
      color: #fff;
      white-space: pre;
      /* margin-bottom:15px; */
      &:hover {
        border: 1px solid #00c040;
        color: #00c040;
      }
    }
    .card-full-v-content-blurb {
      font-weight: 300;
      color: rgba(0, 2, 28, 0.75);
      margin: 10px 0 0 0;
    }
    .border-style-full-v {
      border-top: 1px solid rgba(240, 240, 240);
      margin: 15px 0 5px 0;
    }
    .card-full-v-content-meta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        margin-right: 5px;
      }
      svg,
      span {
        vertical-align: middle;
        color: rgba(0, 2, 28, 0.75);
      }
      .byline-card-full-v {
        float: left;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        /* span {
            color: #00c040
          } */
      }
      .date-count-card-full-v {
        font-size: 14px;
        float: right;
        svg {
          margin-bottom: 2px;
        }
      }

      .pageview {
        margin-left: 10px;
        svg {
          margin-bottom: 3px;
        }
      }
    }
  }
`;

export default CardFullV;
