import { useEffect, useState } from 'react';
// import {
//  AiOutlineEye
// } from 'react-icons/ai';
import Image from 'src/components/image';
import { incrementCountview } from 'src/utils/increment-countview';
import { useDateUpdate, usePageViews } from 'src/utils/helper';
import styled from 'styled-components';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { FaRegCalendarAlt, FaRegEye } from 'react-icons/fa';
const CardH = ({ data, path, showBlurb = false, showTime = true, external = false, excerpt = 2, block = 1, showCountview = true, eventDataLayer }) => {
  const badge = data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;
  if (!data) return null;
  const [countView, setCountView] = useState(0);

  useEffect(async () => {
    await incrementCountview(data)
      .then(item => setCountView(item))
      .catch(() => setCountView(0));
  }, []);

  const dateUpdate = useDateUpdate(data?.published_at);

  return (
    <>
      <a
        tabIndex='-1'
        title={data?.title}
        target={external ? '_blank' : '_self'}
        rel={external ? 'noreferrer noopener follow' : ''}
        href={path}
        //! DATALAYER
        onClick={() =>
          sendDataLayerGlobal({
            ...eventDataLayer
          })
        }
      >
        <CardHStyle className='card-h'>
          <div className='row'>
            <div className='col-5 img-scale-animate'>
              <Image image={data?.image} title={data?.title} external={external} type='CARD_SMALL' />
            </div>
            <div className={`${block && `block-style-${block}`} col-7 d-flex-only d-lg-block readmore`}>
              <h3 className={`text-excerpt-${excerpt} m-0`}>{data?.title}</h3>
              {showBlurb && <p className='blurb text-excerpt-2'>{data?.blurb}</p>}
              {/* {(showBlurb && data?.blurb) && <p className='blurb text-excerpt-2'>{data?.blurb}</p>} */}
              <div className='meta'>
                {showTime && (
                  <>
                    <div className='border-style-card-h' />
                    <div className='post-date-light'>
                      <ul>
                        <li className='date'>
                          <FaRegCalendarAlt />
                          <span>{dateUpdate}</span>
                        </li>
                        {showCountview && (countView > 0 || data.pageviews > 0) && (
                          <li className='pageview'>
                            <FaRegEye /> {usePageViews(countView) || usePageViews(data?.pageviews)}
                          </li>
                        )}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </CardHStyle>
      </a>
    </>
  );
};

const CardHStyle = styled.div`
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border-radius: 3px;
  cursor: pointer;
  .blurb {
    font-size: 13px;
    font-weight: 300;
    color: rgba(0, 2, 28, 0.85);
    margin: 5px 0 0 0;
  }
  &:hover {
    h3 {
      color: #00c040;
    }
  }
  &.card-h {
    margin-top: 1rem;
    /* @media (max-width: 768px) {
      margin-top:30px;
    } */
    .img-scale-animate {
      position: relative;
      padding: 0;
      /* box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 18%);
      -webkit-box-shadow: 1px 1px 4px 0px rgb(0 0 0 / 18%);
      -moz-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.18); */
      border: 1px solid rgba(240, 240, 240);
      /* border-radius: 3px 0px 0px 3px; */
      @media (max-width: 1024px) and (min-width: 767px) {
        padding-left: 0;
      }
    }
    .resolution-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px 0px 0px 3px;

      @media (max-width: 1024px) and (min-width: 767px) {
        position: relative;
      }
    }
    h3 {
      transition: 0.5s ease-out;
      font-size: 16px;
      line-height: 1.75;
      padding: 8px 8px 0 0;
      font-weight: 500;
    }
    /* .border {
      border: 1px solid rgba(244,244,244);
      border-left: none;
      box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.18);
      -webkit-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.18);
      -moz-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.18);
      padding: 5px 10px;
    } */
    .d-flex-only {
      @media (max-width: 1024px) and (min-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
    }
    .border-style-card-h {
      margin: 5px 10px 5px 0px;
      border-top: 1px solid rgba(240, 240, 240);
    }
    .post-date-light {
      padding: 0 10px 5px 0;
      ul {
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        padding-left: 0;
        margin-top: 0;
        li {
          svg {
            margin-right: 5px;
            margin-bottom: 2px;
          }
          svg,
          span {
            vertical-align: middle;
            font-size: 12px;
            font-weight: 300;
            color: rgba(0, 2, 28, 0.75);
          }
          @media (max-width: 768px) {
            svg,
            span {
              font-size: 11px;
            }
          }
        }
        .pageview {
          font-size: 12px;
          font-weight: 300;
          color: rgba(0, 2, 28, 0.75);
          margin-top: 5px;
          svg {
            margin-bottom: 3px;
          }
        }
      }
    }
  }
`;

export default CardH;
